const initialState = {
  modal_customer_transaction_game: {
    data: [],
    total: -1
  },
  modal_customer_activity: {
    data: [],
    total: -1
  },
  modal_customer_transaction_credit: {
    data: [],
    total: -1
  },
  modal_customer_invite_friend: {
    data: [],
    total: -1
  },
  modal_customer_promotion: {
    data: [],
    total: -1
  },
  modal_customer_shop: {
    data: [],
    total: -1
  },
  modal_customer_wallets: {
    data: [],
    total: -1
  },
  modal_customer_banks: {
    data: [],
    total: -1,
    log: []
  },

  //payment
  bank_bot_list: {
    data: [],
    total: -1,
    params: {}
  },
  deposit_list: {
    data: [],
    total: -1,
    params: {}
  },
  withdraw_list: {
    data: [],
    total: -1,
    params: {}
  },
  organization_agentbank_list: {
    data: [],
    total: -1,
    params: {}
  },

  //customer
  customer_list: {
    data: [],
    total: -1,
    params: {}
  },

  //report
  win_loss_list: {
    data: [],
    total: -1,
    params: {}
  },
  win_loss_game_list: {
    data: [],
    total: -1,
    params: {}
  },
  win_loss_customer_list: {
    data: [],
    total: -1,
    params: {}
  },
  commission_list: {
    data: [],
    total: -1,
    params: {}
  },
  commission_category_list: {
    data: [],
    total: -1,
    params: {}
  },
  cashback_list: {
    data: [],
    total: -1,
    params: {}
  },
  cashback_category_list: {
    data: [],
    total: -1,
    params: {}
  },
  game_cmap_list: {
    data: [],
    total: -1,
    params: {}
  },
  deposit_again_list: {
    data: [],
    total: -1,
    params: {}
  },
  customer_referral_summary: {
    data: [],
    total: -1,
    params: {}
  },

  //setting
  game_list: {
    data: [],
    total: -1,
    params: {}
  },
  image_game_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_event_list: {
    data: [],
    total: -1,
    params: {}
  },
  banner_slide_hot_list: {
    data: [],
    total: -1,
    params: {}
  },
  setting_contact_list: {
    data: [],
    total: -1,
    params: {}
  },
  //lottery
  admin_round_list: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_ticket: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_ticket_number: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_special: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_unlimit: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_price: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_summary: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_number: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_unlimit: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_bill: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_summary_user: {
    data: [],
    total: -1,
    params: {}
  },
  lottery_winloss: {
    data: [],
    total: -1,
    params: {}
  },
  customer_list: {
    data: [],
    total: -1,
    params: {}
  }
}

export default initialState